$background-color: #fafafa;
$white-color: #fff;
$black-color: #212529;
$gray-color: #aeaeae;
$gray-color-text: #495057;
$red-color: #ff6347;

$header-and-footer-padding-desktop: 80px;
$header-and-footer-padding-phone: 40px;
$header-heigth: 80px;
$header-heigth-phone: 60px;
$footer-heigth: 150px;
$font-size-h1: 36px;

$footer-height-phone: 400px;
$height-project-list: 288px;

$width-top-section-about: 544px;

$width-page-desktop: 1120px;
$width-page-table: 735px;
$width-page-phone: 320px;
$width-page-phone-SE: 300px;
$width-project-page: calc(100% - 128px);
$width-project-page-other: calc(100% - 250px);

$heigth-home-page-top: 800px;
$heigth-home-page-desktop: 1400px;

$margin-bottom-home-page-desktop: 80px;
$margin-bottom-home-page-categorys-title-desktop: 80px;
$margin-bottom-home-page-categorys-category-desktop: 60px;

$width-project-img-desktop: 350px;
$heigth-project-img-desktop: 250px;
$margin-project-tale-desktop: 35px;

$size-button: 50px;
$margin-button: 75px;

$heigth-memeber-photo: 260px;
$width-memeber-photo: 220px;

$most-margin-about-us: 100px;
$width-top-img-about-us: 570px;
$heigth-top-img-about-us: 265px;

$input-heigth: 15%;
$input-width: 100%;
$textarea-heigth: 100%;

$margin-top-input: 16px;
