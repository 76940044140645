@import "../../../src/styles/utils.scss";

.Contacts__center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.Contacts {
  margin: $most-margin-about-us 0;
  display: block;

  &__sections {
    display: flex;

    & :not(:last-child) {
      margin-right: $most-margin-about-us;
    }
  }

  &__title {
    text-align: left;
    @include h2;
  }

  &__section {
    &__info {
      text-align: left;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: $margin-project-tale-desktop;
    }

    &__title {
      text-align: left;
      @include h3-second;
      margin-bottom: 16px;
    }

    &__list {
      list-style: none;

      & :not(:last-child) {
        margin-bottom: 16px;
      }

      &__item {
        display: flex;
        justify-content: left;
      }

      &__link {
        position: relative;
        @include h3;
        text-transform: uppercase;

        text-align: left;
        text-decoration: none;

        @include hoverLinks;
      }
    }
  }
}

@media (max-width: 700px) {
  .Footer__container {
    margin-top: 50px;
  }

  .Contacts {
    margin: 0;
    width: $width-page-phone-SE;

    &__title {
      @include h2-for-phone;
      margin-bottom: 60px;
    }

    &__sections {
      display: block;

      & > * {
        margin-bottom: $most-margin-about-us;
        margin-right: 0;

        & > * {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media (min-width: 700px) and (max-width: 1199px) {
  .Footer__container {
    margin-top: 50px;
  }

  .Contacts {
    &__sections {
      display: block;

      & > * {
        margin-bottom: $most-margin-about-us;
        margin-right: 0;

        & > * {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .Contacts__center {
    height: 100vh;
  }
}
