/* Задний фон для затемнения */
.carousel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* полупрозрачный черный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Поверх всего */
}

.carousel-content {
  position: relative;
  width: 80%;
  max-width: 900px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  touch-action: pan-y; /* Позволяет вертикальный скроллинг без блокировки свайпа */
}

.carousel-slide {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slide img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  opacity: 0; /* По умолчанию все изображения невидимы */
  transition: opacity 0.5s ease-in-out;
}

.carousel-slide img.active {
  opacity: 1; /* Видимость активного изображения */
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  font-size: 2rem;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 10;
}

.carousel-button.prev {
  left: 20px;
}

.carousel-button.next {
  right: 20px;
}

.carousel-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 450px) {
  .carousel-button {
    display: none;
  }

  .carousel-content {
    height: 350px;
  }
}
