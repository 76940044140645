@import "../../../src/styles/utils.scss";

.margin-top {
  margin-top: 60px;
}
.margin-bottom-text-left {
  margin-bottom: 60px;
  text-align: left;
}

.ProjectPage__center {
  @include center;

  overflow-y: hidden;
}

.button__other {
  bottom: 50%;
}

.ProjectPage {
  overflow: hidden;

  &__tour {
    display: inline-block;
    margin: auto;
    width: 100%;
    height: $heigth-home-page-top;

    &__title {
      text-align: center;
      @include h2;
    }

    &__frame {
      width: 100%;
      height: 100%;
      margin-bottom: $most-margin-about-us;
    }
  }

  &__other-projects {
    position: relative;
    width: 500px;
    justify-content: center;
    width: $width-project-page-other;
    margin: auto;
    margin-bottom: $most-margin-about-us;

    &__center {
      position: relative;
    }
  }

  &__top {
    width: 100vw;
    display: flex;
    overflow: hidden;
    position: relative;

    &__img {
      height: 100%;
    }

    &__bottom-slide {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 45%;
      transition: all 0.5s;
      transform: translateY(100%);
      background-color: rgba($black-color, 0.3);
    }

    &__title {
      color: #212529;
      font-weight: 450;
      text-transform: uppercase;
      color: #fafafa;
      position: absolute;
      z-index: 1;
      padding: 25px 75px;
      width: 100%;
      height: 100%;
      position: sticky;
      @include h1;
    }
  }

  &__description__center {
    @include center;
    width: $width-project-page;
    margin: auto;
    text-align: left;
  }

  &__description {
    width: $width-page-desktop;
    margin-top: $most-margin-about-us;

    &__path {
      display: flex;
      text-transform: uppercase;
      @include text;
      text-decoration: none;

      & :last-child {
        cursor: auto;
        pointer-events: all;
      }

      & :not(:last-child) {
        display: flex;

        &::after {
          content: ">";
          display: flex;
          width: 10px;
          height: 10px;
          padding: 0 20px;
          color: $gray-color;
        }

        &:hover {
          color: $black-color;
        }
      }

      margin-bottom: 60px;

      &__item {
        cursor: pointer;
        font-size: 16px;
        text-decoration: none;
        color: $gray-color;
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: $margin-bottom-home-page-desktop;

      &__list {
        list-style: none;
        @include text;
        color: $black-color;

        & > *:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      &__button {
        @include buttonContact;
        margin-top: 0;
      }
    }

    &__text {
      @include text;
      color: $black-color;
      margin-bottom: $margin-bottom-home-page-desktop;
    }
  }

  &__images {
    &__center {
      display: flex;
      flex-direction: row; // Фотографии по умолчанию размещаются в одну линию
      flex-wrap: wrap; // Если недостаточно места, элементы переносятся на новую строку
      justify-content: center;
    }

    &__container__big-img {
      cursor: pointer;
      width: calc(
        $width-project-page / 2
      ); // Каждое изображение будет занимать половину доступного пространства
      height: auto; // Автоматическая высота для сохранения пропорций
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      opacity: 0;
      transition: all 1s ease-in-out;

      &.visible {
        opacity: 1;
      }

      &.hidden {
        opacity: 0;
      }
    }

    &__big-img {
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .ProjectPage__description {
    margin-top: $margin-bottom-home-page-desktop / 2;

    &__top {
      margin-bottom: 0;
    }

    &__text {
      margin-bottom: $margin-bottom-home-page-desktop / 2;
    }
  }

  .ProjectPage__description__path {
    display: none;
  }

  .ProjectPage__top {
    justify-content: center;
  }

  .ProjectPage__description__top {
    display: block;
  }
  .ProjectPage__description__top__button {
    margin: 40px 0;
  }

  .ProjectPage__images__container__big-img {
    width: auto;
  }
  .ProjectPage__other-projects {
    width: $width-page-phone-SE;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .ProjectPage {
    &__images {
      &__duo-img {
        &__container-litle {
          width: 300px;
          height: 500px;
        }

        &__container-big {
          width: 500px;
        }
      }
    }
  }
}
