@import "../../styles/utils.scss";

.gif__background {
  opacity: 0.05;
  top: 0;

  position: absolute;
  width: 100%;
  height: 100%;
  right: 50%;
  transform: translateX(50%);
  background-image: url(../../../public/footer-animation.webp);
  background-position-y: -200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.Footer {
  background-color: $black-color;
  box-shadow: 0 2px 5px rgba(1, 0, 0, 0.1);
  position: relative;
  display: flex;
  padding: 30px 80px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  &__address {
    position: relative;
    text-decoration: none;
    color: $background-color;

    @include hoverLinksWhite();
  }

  &__list {
    display: flex;
    list-style: none;

    & :not(:last-child) {
      margin-right: 60px;
    }

    &__item {
      display: flex;
    }

    &__link {
      position: relative;
      text-decoration: none;
      color: $background-color;
      text-transform: uppercase;
      
      @include hoverLinksWhite();

      &--facebook {
        &::before {
          content: url(../../../public/icons/Vector-f.svg);
          position: absolute;
          bottom: -4px;
          display: flex;
          width: 35px;
          height: 25px;
          transform: translate(-100%);
          justify-content: center;
        }
      }

      &--instagram {
        &::before {
          content: url(../../../public/icons/ri_instagram-line.svg);
          position: absolute;
          bottom: -3px;
          display: flex;
          width: 35px;
          height: 25px;
          transform: translate(-100%);
          justify-content: center;
        }
      }

      &--linkedin {
        &::before {
          content: url(../../../public/icons/Vector-link.svg);
          position: absolute;
          bottom: -4px;
          display: flex;
          width: 35px;
          height: 25px;
          transform: translate(-100%);
          justify-content: center;
        }
      }
    }
  }

  &__log-in {
    margin-top: 16px;
    position: relative;
    width: fit-content;

    &__center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__link {
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      color: $background-color;
      @include hoverLinksWhite;
    }
  }
}

@media (max-width: 600px) {
  .gif__background {
    background-position-y: 0;
  }

  .Footer__address__container {
    margin-top: 80px;
    width: fit-content;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }

  .phone-media-footer {
    display: block;
    height: $footer-height-phone;
  
    &__list {
      margin-top: 90px;
      display: block;
      position: relative;
      left: 50%;
      width: fit-content;
      transform: translate(-25%);
    }
  }

  .Footer__list__item {
    width: fit-content;
    margin-bottom: 16px;
  }
}

@media (min-width: 600px) and (max-width: 1180px) {
  .Footer__address__container {
    margin-top: 60px;
    width: fit-content;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }

  .phone-media-footer {
    display: block;

    &__list {
      margin-top: 48px;
    }
  }

  .Footer__list__container {
    display: flex;
    justify-content: center;
  }

  .Footer__list__item {
    width: fit-content;
  }
}

@media (min-width: 1025px) {
  .phone-media-footer {
  }
}
