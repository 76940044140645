@import "../../../src/styles/utils.scss";

.Header {
  display: flex;
  width: 100%;
  height: $header-heigth;
  justify-content: space-between;
  align-items: center;
  padding: 0 $header-and-footer-padding-desktop;
  position: fixed;
  top: 0;
  background-color: $white-color;
  z-index: 33;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 1s;
 
  &__menu {
    &__burger-menu {
      cursor: pointer;
      display: flex;
      background: url(../../../public/icons/MenuFrame.svg);
      width: 82px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include hover(transform, scale(1.2));
    }
  }

  &__logo {
    width: 90px;
    height: 70px;
    background-image: url(../../../public/icons/HAB-антрацит.svg);
    background-size: cover;
    background-repeat: no-repeat;

    @include hover(transform, scale(1.2));
  }

  &__list {
    display: flex;
    list-style: none;

    & :not(:last-child) {
      margin-right: 80px;
    }

    &__item {
      display: flex;
      align-items: center;
      width: 90px;
    }

    &__link {
      text-decoration: none;
      text-transform: uppercase;
      color: $black-color;
      position: relative;
      transition: all 0.3s;

      @include hoverLinks();

      @include activeLink();
    }
  }

  &__language {
    & :not(:last-child) {
      margin-right: 20px;
    }

    &__link {
      cursor: pointer;
      text-decoration: none;
      color: $gray-color;
      outline: 0;
      border: 0;
      background-color: $white-color;

      &--active {
        font-weight: 500;
        color: $black-color;
      }

      &:hover {
        color: $black-color;
      }
    }
  }
}

@media (max-width: 600px) {
  .Header {
    padding: 30px $header-and-footer-padding-phone;
    height: $header-heigth-phone;
  }

  .phone-media {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  .Header {
    padding: 30px $header-and-footer-padding-phone;

 
  }

  .phone-media {
    display: none;
  }
}

@media (min-width: 1200px) {
  .Header {
    &__menu {
      display: none;
    }
  }
}
